import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Input, jsonFormatText, Modal, Textarea, useNotification} from "@vokymlak/kabinet-ui";

function ModalBranchAdd(props) {;
  const [isMain, setIsMain] = useState(false);

  const inputShortName = useRef(null);
  const inputName = useRef(null)
  const inputOgrn = useRef(null);
  const inputInn = useRef(null);
  const inputKpp = useRef(null);
  const inputBank = useRef(null);
  const inputInvoiceNumber = useRef(null);
  const inputDirectorFullName = useRef(null);
  const inputDirectorShortName = useRef(null);
  const inputPhone = useRef(null);
  const inputEmail = useRef(null);
  const inputSite = useRef(null);


  const {notification} = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const shortName = jsonFormatText(inputShortName.current.value);
    const name = jsonFormatText(inputName.current.value);
    const ogrn = jsonFormatText(inputOgrn.current.value);
    const inn = jsonFormatText(inputInn.current.value);
    const kpp = jsonFormatText(inputKpp.current.value);
    const bank = jsonFormatText(inputBank.current.value);
    const invoiceNumber = jsonFormatText(inputInvoiceNumber.current.value);
    const directorFullName = jsonFormatText(inputDirectorFullName.current.value);
    const directorShortName = jsonFormatText(inputDirectorShortName.current.value);
    const phone = jsonFormatText(inputPhone.current.value);
    const email = jsonFormatText(inputEmail.current.value);
    const site = jsonFormatText(inputSite.current.value);

    const jsonData = JSON.stringify({
      short_name: shortName,
      name: name,
      is_main: isMain,
      ogrn: ogrn,
      inn: inn,
      kpp: kpp,
      bank: bank,
      invoice_number: invoiceNumber,
      director_full_name: directorFullName,
      director_short_name: directorShortName,
      phone: phone,
      email: email,
      site: site,
    });

    axios
      .post("/api/branch/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Филиал добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <>
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый
            <div className="Text__signature">Филиал</div>
          </div>

          <div className="Form__field">
            <Textarea reference={inputShortName} label="Краткое наименование"/>
          </div>

          <div className="Form__field">
            <Textarea reference={inputName} label="Полное наименование"/>
          </div>

          <div className="Form__field">
            <Input reference={inputOgrn} label="ОГРН"/>
          </div>

          <div className="Form__field">
            <Input reference={inputInn} label="ИНН"/>
          </div>

          <div className="Form__field">
            <Input reference={inputKpp} label="КПП"/>
          </div>

          <div className="Form__field">
            <Textarea reference={inputBank} label="Банк"/>
          </div>

          <div className="Form__field">
            <Input reference={inputInvoiceNumber} label="Номер счёта"/>
          </div>

          <div className="Form__field">
            <Input reference={inputDirectorFullName} label="Полное ФИО директора"/>
          </div>

          <div className="Form__field">
            <Input reference={inputDirectorShortName} label="Фамилия и инициалы директора"/>
          </div>

          <div className="Form__field">
            <Input reference={inputPhone} label="Телефон" />
          </div>

          <div className="Form__field">
            <Input reference={inputEmail} label="Емайл" />
          </div>

          <div className="Form__field">
            <Input reference={inputSite} label="Сайт" />
          </div>

          <div className="Form__field Form__field_last">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isMain
                  ? "2px solid #ffe082"
                  : "2px dashed #20202015",
                backgroundColor: isMain ? "#ffe082" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsMain(!isMain)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Основной филиал
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isMain && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ModalBranchAdd;
