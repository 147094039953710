import React, {useRef} from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Select,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function ModalBranchAddressAdd(props) {
  const selectAddressType = useRef(null);

  const inputCountry = useRef(null);
  const inputPostCode = useRef(null);
  const inputRegion = useRef(null);
  const inputCity = useRef(null);
  const inputDistrict = useRef(null);
  const inputStreet = useRef(null);
  const inputHouseNum1 = useRef(null);
  const inputHouseNum2 = useRef(null);
  const inputFloor = useRef(null);
  const inputRoomNumber = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/branch/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {notification} = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const country = jsonFormatText(inputCountry.current.value);
    const postCode = jsonFormatText(inputPostCode.current.value);
    const region = jsonFormatText(inputRegion.current.value);
    const city = jsonFormatText(inputCity.current.value);
    const district = jsonFormatText(inputDistrict.current.value);
    const street = jsonFormatText(inputStreet.current.value);
    const houseNum1 = jsonFormatText(inputHouseNum1.current.value);
    const houseNum2 = jsonFormatText(inputHouseNum2.current.value);
    const floor = jsonFormatText(inputFloor.current.value);
    const roomNumber = jsonFormatText(inputRoomNumber.current.value);

    let addressType = selectAddressType.current.value;
    addressType = addressType.length > 0
      ? addressType === "null"
        ? null
        : addressType
      : null

    const jsonData = JSON.stringify({
      address_type: !!addressType ? {
        tag: addressType,
      } : null,
      country: country,
      post_code: postCode,
      region: region,
      city: city,
      district: district,
      street: street,
      house_num1: houseNum1,
      house_num2: houseNum2,
      floor: floor,
      room_number: roomNumber
    });

    axios
      .post("/api/branch/" + props.object.uuid + "/add/address", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Адрес добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (!!object &&
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          {(!!object.short_name && object.short_name) || "-"}
          <div className="Text__signature">Филиал</div>
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={selectAddressType}
            options={[
              {
                value: "null",
                title: "Не выбрано",
              },
              {
                value: "LEGAL",
                title: "Юридический адрес",
              },
              {
                value: "ACTUAL",
                title: "Фактический адрес",
              },
            ]}
            label="Тип адреса"
          />
        </div>

        <div className="Form__field ">
          <Input reference={inputCountry} label="Страна"/>
        </div>

        <div className="Form__field">
          <Input reference={inputPostCode} label="Почтовый индекс"/>
        </div>

        <div className="Form__field">
          <Input reference={inputRegion} label="Регион"/>
        </div>

        <div className="Form__field">
          <Input reference={inputCity} label="Город"/>
        </div>

        <div className="Form__field">
          <Input reference={inputDistrict} label="Район"/>
        </div>

        <div className="Form__field">
          <Input reference={inputStreet} label="Улица"/>
        </div>

        <div className="Form__field">
          <Input reference={inputHouseNum1} label="Номер дома"/>
        </div>

        <div className="Form__field">
          <Input reference={inputHouseNum2} label="Дополнение к номеру дома"/>
        </div>

        <div className="Form__field">
          <Input reference={inputFloor} label="Этаж"/>
        </div>

        <div className="Form__field Form__field_last">
          <Input reference={inputRoomNumber} label="Номер офиса"/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalBranchAddressAdd;
