import React, {useRef, useState} from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Select,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function ModalBranchAddressEdit(props) {
  const selectAddressType = useRef(null);

  const inputCountry = useRef(null);
  const inputPostCode = useRef(null);
  const inputRegion = useRef(null);
  const inputCity = useRef(null);
  const inputDistrict = useRef(null);
  const inputStreet = useRef(null);
  const inputHouseNum1 = useRef(null);
  const inputHouseNum2 = useRef(null);
  const inputFloor = useRef(null);
  const inputRoomNumber = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/branch-address/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const country = jsonFormatText(inputCountry.current.value);
    const postCode = jsonFormatText(inputPostCode.current.value);
    const region = jsonFormatText(inputRegion.current.value);
    const city = jsonFormatText(inputCity.current.value);
    const district = jsonFormatText(inputDistrict.current.value);
    const street = jsonFormatText(inputStreet.current.value);
    const houseNum1 = jsonFormatText(inputHouseNum1.current.value);
    const houseNum2 = jsonFormatText(inputHouseNum2.current.value);
    const floor = jsonFormatText(inputFloor.current.value);
    const roomNumber = jsonFormatText(inputRoomNumber.current.value);

    let addressType = selectAddressType.current.value;
    addressType = addressType.length > 0
      ? addressType === "null"
        ? null
        : addressType
      : null

    const jsonData = JSON.stringify({
      address_type: !!addressType ? {
        tag: addressType,
      } : null,
      country: country,
      post_code: postCode,
      region: region,
      city: city,
      district: district,
      street: street,
      house_num1: houseNum1,
      house_num2: houseNum2,
      floor: floor,
      room_number: roomNumber
    });

    axios
      .put("/api/branch-address/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };


  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/branch-address/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Адрес филиала удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (!!object &&
    <Modal handleClose={() => props.handleClose()}>
      {!isDelete && (
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div className="Form__title">
            Адрес филиала
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectAddressType}
              options={[
                {
                  value: "null",
                  title: "Не выбрано",
                },
                {
                  value: "LEGAL",
                  title: "Юридический адрес",
                },
                {
                  value: "ACTUAL",
                  title: "Фактический адрес",
                },
              ]}
              label="Тип адреса"
              value={!!object.address_type && object.address_type.tag}
            />
          </div>

          <div className="Form__field ">
            <Input reference={inputCountry} label="Страна"
                   value={!!object.country && object.country}/>
          </div>

          <div className="Form__field">
            <Input reference={inputPostCode} label="Почтовый индекс"
                   value={!!object.post_code && object.post_code}/>
          </div>

          <div className="Form__field">
            <Input reference={inputRegion} label="Регион"
                   value={!!object.region && object.region}/>
          </div>

          <div className="Form__field">
            <Input reference={inputCity} label="Город"
                   value={!!object.city && object.city}/>
          </div>

          <div className="Form__field">
            <Input reference={inputDistrict} label="Район"
                   value={!!object.district && object.district}/>
          </div>

          <div className="Form__field">
            <Input reference={inputStreet} label="Улица"
                   value={!!object.street && object.street}/>
          </div>

          <div className="Form__field">
            <Input reference={inputHouseNum1} label="Номер дома"
                   value={!!object.house_num1 && object.house_num1}/>
          </div>

          <div className="Form__field">
            <Input reference={inputHouseNum2} label="Дополнение к номеру дома"
                   value={!!object.house_num2 && object.house_num2}/>
          </div>

          <div className="Form__field">
            <Input reference={inputFloor} label="Этаж"
                   value={!!object.floor && object.floor}/>
          </div>

          <div className="Form__field Form__field_last">
            <Input reference={inputRoomNumber} label="Номер офиса"
                   value={!!object.room_number && object.room_number}/>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div
              className="Action__link Action__link_red"
              onClick={() => setIsDelete(true)}
            >
              Удалить
            </div>
          </div>
        </form>
      )}
      {isDelete && (
        <form className="Form" onSubmit={(event) => changeDelete(event)}>
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            Адрес филиала
            <div className="Text__signature">Удалить</div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_red">
              Удалить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => setIsDelete(false)}>
              Отмена
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
}

export default ModalBranchAddressEdit;
