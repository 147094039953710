import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Textarea,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

function ModalBranchEdit(props) {
  const [isMain, setIsMain] = useState(false);

  const inputShortName = useRef(null);
  const inputName = useRef(null);
  const inputOgrn = useRef(null);
  const inputInn = useRef(null);
  const inputKpp = useRef(null);
  const inputBank = useRef(null);
  const inputInvoiceNumber = useRef(null);
  const inputDirectorFullName = useRef(null);
  const inputDirectorShortName = useRef(null);
  const inputPhone = useRef(null);
  const inputEmail = useRef(null);
  const inputSite = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/branch/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsMain(!!data && data.is_main);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const shortName = jsonFormatText(inputShortName.current.value);
    const name = jsonFormatText(inputName.current.value);
    const ogrn = jsonFormatText(inputOgrn.current.value);
    const inn = jsonFormatText(inputInn.current.value);
    const kpp = jsonFormatText(inputKpp.current.value);
    const bank = jsonFormatText(inputBank.current.value);
    const invoiceNumber = jsonFormatText(inputInvoiceNumber.current.value);
    const directorFullName = jsonFormatText(inputDirectorFullName.current.value);
    const directorShortName = jsonFormatText(inputDirectorShortName.current.value);
    const phone = jsonFormatText(inputPhone.current.value);
    const email = jsonFormatText(inputEmail.current.value);
    const site = jsonFormatText(inputSite.current.value);

    const jsonData = JSON.stringify({
      short_name: shortName,
      name: name,
      is_main: isMain,
      ogrn: ogrn,
      inn: inn,
      kpp: kpp,
      bank: bank,
      invoice_number: invoiceNumber,
      director_full_name: directorFullName,
      director_short_name: directorShortName,
      phone: phone,
      email: email,
      site: site,
    });

    axios
      .put("/api/branch/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("api/branch/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Филиал удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isDelete && (
            <form className="Form" onSubmit={(event) => changeEdit(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {(!!object.short_name && object.short_name) || "-"}
                <div className="Text__signature">Филиал</div>
              </div>

              <div className="Form__field">
                <Textarea reference={inputShortName} label="Краткое наименование"
                          value={!!object.short_name && object.short_name}/>
              </div>

              <div className="Form__field">
                <Textarea reference={inputName} label="Полное наименование"
                          value={!!object.name && object.name}/>
              </div>

              <div className="Form__field">
                <Input reference={inputOgrn} label="ОГРН"
                       value={!!object.ogrn && object.ogrn}/>
              </div>

              <div className="Form__field">
                <Input reference={inputInn} label="ИНН"
                       value={!!object.inn && object.inn}/>
              </div>

              <div className="Form__field">
                <Input reference={inputKpp} label="КПП"
                       value={!!object.kpp && object.kpp}/>
              </div>

              <div className="Form__field">
                <Textarea reference={inputBank} label="Банк"
                          value={!!object.bank && object.bank}/>
              </div>

              <div className="Form__field">
                <Input reference={inputInvoiceNumber} label="Номер счёта"
                       value={!!object.invoice_number && object.invoice_number}/>
              </div>

              <div className="Form__field">
                <Input reference={inputDirectorFullName} label="Полное ФИО директора"
                       value={!!object.director_full_name && object.director_full_name}/>
              </div>

              <div className="Form__field">
                <Input reference={inputDirectorShortName} label="Фамилия и инициалы директора"
                       value={!!object.director_short_name && object.director_short_name}/>
              </div>

              <div className="Form__field">
                <Input reference={inputPhone} label="Телефон"
                       value={!!object.phone && object.phone}/>
              </div>

              <div className="Form__field">
                <Input reference={inputEmail} label="Емайл"
                       value={!!object.email && object.email}/>
              </div>

              <div className="Form__field">
                <Input reference={inputSite} label="Сайт"
                       value={!!object.site && object.site}/>
              </div>

              <div className="Form__field Form__field_last">
                <div
                  className="Button__swith"
                  style={{
                    padding: "8px",
                    width: "100%",
                    border: isMain
                      ? "2px solid #ffe082"
                      : "2px dashed #20202015",
                    backgroundColor: isMain ? "#ffe082" : "#ffffff",
                    borderRadius: 6,
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsMain(!isMain)}
                >
                  <div
                    className="Swith__text"
                    style={{
                      fontSize: 17,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Основной филиал
                  </div>
                  <div
                    className={
                      "Swith Swith_gray" +
                      ((isMain && " Swith_active Swith_active_gray") || "")
                    }
                  ></div>
                </div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue">
                  Применить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div
                  className="Action__link Action__link_red"
                  onClick={() => setIsDelete(true)}
                >
                  Удалить
                </div>
              </div>
            </form>
          )}
          {isDelete && (
            <form className="Form" onSubmit={(event) => changeDelete(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
              {(!!object.short_name && object.short_name) || "-"}
                <div className="Text__signature">Удалить</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_red">
                  Удалить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsDelete(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>
      </>
    )
  );
}

export default ModalBranchEdit;
