import {useEffect, useRef} from "react";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, useAction, useList, useNotification, useUnroll} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useBranches() {
  const { notification } = useNotification();


  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/branches",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
  });

  const {unrolls, isUnroll, changeUnroll, changeAllUnroll} = useUnroll();

  const {setAction} = useAction(["branches"], () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "branches"]);
    } else {
      setAction(["branches"]);
    }
  }, [list]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value.trim());
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  return {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
  };
}

export default useBranches;
