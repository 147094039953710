import React, {Fragment, useState} from "react";
import {ButtonIcon, Loading, Pagination, Search, Select, TextSearchMarker,} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalBranchAdd from "./ModalBranchAdd";
import ModalBranchEdit from "./ModalBranchEdit";
import ModalBranchAddressAdd from "./ModalBranchAddressAdd";
import ModalBranchAddressEdit from "./ModalBranchAddressEdit";

function BranchesPage(props) {
  const {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
  } = props;


  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalObjectEdit, setModalObjectEdit] = useState(null);
  const [modalItemAdd, setModalItemAdd] = useState(null);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  function formatAddress(address) {
    const parts = [];

    if (address.country) {
      parts.push(address.country);
    }
    if (address.post_code) {
      parts.push(address.post_code);
    }
    if (address.region) {
      parts.push(address.region);
    }
    if (address.city) {
      parts.push(address.city);
    }
    if (address.district) {
      parts.push(address.district);
    }
    if (address.street) {
      parts.push(address.street);
    }
    if (address.house_num1) {
      parts.push(address.house_num1);
    }
    if (address.house_num2) {
      parts.push(address.house_num2);
    }
    if (address.floor) {
      parts.push(`этаж ${address.floor}`);
    }
    if (address.room_number) {
      parts.push(`офис ${address.room_number}`);
    }

    return parts.join(', ');
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Филиалы
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      position: "relative",
                    }}
                  >
                    <div
                      className="Item__title"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="Element__title"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          {!!item.short_name && (
                            <TextSearchMarker
                              text={item.short_name}
                              search={searchQuery}
                            />)}
                          <div style={{
                            fontSize: 12,
                            fontWeight: 400,
                          }}>
                            {!!item.name && (
                              <TextSearchMarker
                                text={item.name}
                                search={searchQuery}
                              />)}
                          </div>
                          <div style={{
                            fontSize: 12,
                            fontWeight: 400,
                          }}>
                            <span style={{marginRight: 8}}>ОГРН:&nbsp;{(!!item.ogrn && (
                              <TextSearchMarker
                                text={item.ogrn}
                                search={searchQuery}
                              />)) || "-"}</span>
                            <span style={{marginRight: 8}}>ИНН:&nbsp;{(!!item.inn && (
                              <TextSearchMarker
                                text={item.inn}
                                search={searchQuery}
                              />)) || "-"}</span>
                            <span style={{marginRight: 8}}>КПП:&nbsp;{(!!item.kpp && (
                              <TextSearchMarker
                                text={item.kpp}
                                search={searchQuery}
                              />)) || "-"}</span>
                          </div>
                          <div style={{
                            fontSize: 12,
                            fontWeight: 400,
                          }}>
                            <span style={{marginRight: 8}}>Банк:&nbsp;{(!!item.bank && item.bank) || "-"}</span>
                          </div>
                          <div style={{
                            fontSize: 12,
                            fontWeight: 400,
                          }}>
                            <span style={{marginRight: 8}}>Номер&nbsp;счёта:&nbsp;{(!!item.invoice_number && (
                              <TextSearchMarker
                                text={item.invoice_number}
                                search={searchQuery}
                              />)) || "-"}</span>
                          </div>
                          <div style={{
                            fontSize: 12,
                            fontWeight: 400,
                          }}>
                            <span style={{marginRight: 8}}>Полное&nbsp;ФИО&nbsp;директора:&nbsp;
                              {(!!item.director_full_name && (item.director_full_name)) || "-"}
                            </span>
                          </div>
                          <div style={{
                            fontSize: 12,
                            fontWeight: 400,
                          }}>
                            <span style={{marginRight: 8}}>Фамилия&nbsp;и&nbsp;инициалы&nbsp;директора:&nbsp;
                              {(!!item.director_short_name && (item.director_short_name)) || "-"}
                            </span>
                          </div>
                          <div style={{
                            fontSize: 12,
                            fontWeight: 400,
                          }}>
                            <span style={{marginRight: 8}}>Телефон:&nbsp;
                              {(!!item.phone && (item.phone)) || "-"}
                            </span>
                            <span style={{marginRight: 8}}>Емайл:&nbsp;
                              {(!!item.email && (item.email)) || "-"}
                            </span>
                            <span style={{marginRight: 8}}>Сайт:&nbsp;
                              {(!!item.site && (item.site)) || "-"}
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {item.is_main && (
                            <div
                              style={{
                                    fontSize: 11,
                                    fontWeight: 700,
                                    backgroundColor: "#202020",
                                    display: "inline-block",
                                    color: "white",
                                    padding: "2px 4px 2px",
                                    borderRadius: 3,
                                    margin: "0 2px",
                                  }}
                              >
                                Основной
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalItemAdd(item)}
                          >
                            Добавить адрес
                          </div>
                        </div>
                        <div className="Header__settings">
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{marginLeft: 12}}
                            onClick={() => setModalObjectEdit(item)}
                          />
                        </div>
                      </div>

                      <table className="Table">
                        <tbody>
                        {item.branch_addresses &&
                          item.branch_addresses.map((address, index) => (
                            <tr
                              className="Table__tr"
                              key={
                                item.uuid + ":" + address.uuid + ":" + index
                              }
                            >
                              <td className="Table__td">
                                <div className="Td__title" style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}>
                                  <span style={{
                                    marginRight: 16
                                  }}>{formatAddress(address)}</span>

                                  {!!address.address_type && (
                                    <div
                                      style={{
                                        fontSize: 11,
                                        fontWeight: 700,
                                        backgroundColor: "#202020",
                                        display: "inline-block",
                                        color: "white",
                                        padding: "2px 4px 2px",
                                        borderRadius: 3,
                                        margin: "0 2px",
                                      }}
                                    >
                                      {address.address_type.name}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className="Table__td Table__td_child">
                                <div
                                  style={{
                                    height: 32,
                                    display: "flex",
                                    marginLeft: 12,
                                  }}
                                >
                                  <ButtonIcon
                                    tooltipText={"Редактировать"}
                                    iconName={"custom-edit"}
                                    iconColor={"#202020"}
                                    size={30}
                                    style={{marginLeft: 12}}
                                    onClick={() => setModalItemEdit(address)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalObjectEdit && (
          <ModalBranchEdit
            object={modalObjectEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalBranchAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}
        {!!modalItemAdd && (
          <ModalBranchAddressAdd
            object={modalItemAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemAdd(null)}
          />
        )}
        {!!modalItemEdit && (
          <ModalBranchAddressEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default BranchesPage;
