import React, { useRef, useState } from "react";
import axios from "axios";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  Input,
  useObject,
  useList, Select, jsonFormatText, Textarea, getAccountFio,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {ROOT_URL} from "../../../../../../config/url";

function ModalItemEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/specification/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });


  const {
    object: [item],
  } = useObject({
    apiUrl: "/api/composition/" + props.item.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const inputDescription = useRef(null);

  const selectMeasurementUnitType = useRef(null);
  const selectComposition = useRef(null);
  const inputValue = useRef(null);

  const { notification } = useNotification();

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };


  const changeAdd = (event) => {
    event.preventDefault();

    const description = jsonFormatText(inputDescription.current.value);
    let measurementUnitType = selectMeasurementUnitType.current.value;
    measurementUnitType = measurementUnitType.length > 0
      ? measurementUnitType === "null"
        ? null
        : measurementUnitType
      : null
    const value = inputValue.current.value;
    let composition = selectComposition.current.value;
    composition = composition.length > 0
      ? composition === "null"
        ? null
        : composition
      : null

    if ((!value || (!!value && value.length === 0)) ||
      !measurementUnitType) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      description: description,
      measurement_unit_type: !!measurementUnitType ? {
        tag: measurementUnitType,
      } : null,
      value: numberConvertV(value),
      replacement: !!composition ? {
        uuid: composition,
      } : null,
    });

    axios
      .put("/api/composition/" + props.item.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };


  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/composition/" + props.item.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };


  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.nomenclature.name,
      };

      options.push(option);
    });

    return options;
  };


  return (!!object &&
     !!item && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeAdd(event)}>
            <div className="Form__title">
              {object.name} - {!!item.nomenclature && item.nomenclature.name}
              <div className="Text__signature">Позиция</div>
            </div>

            <div className="Form__field ">
              <Textarea reference={inputDescription} label="Описание"
                        value={!!item.description && item.description}/>
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={selectMeasurementUnitType}
                options={[
                  {
                    value: "null",
                    title: "Не выбрано",
                  },
                  {
                    value: "MG",
                    title: "Миллиграммы",
                  },
                  {
                    value: "G",
                    title: "Граммы",
                  },
                  {
                    value: "KG",
                    title: "Килограммы",
                  },
                  {
                    value: "ML",
                    title: "Миллилитры",
                  },
                  {
                    value: "L",
                    title: "Литры",
                  },
                  {
                    value: "PCS",
                    title: "Штуки",
                  },
                  {
                    value: "MM",
                    title: "Миллиметры",
                  },
                  {
                    value: "M",
                    title: "Метры",
                  }]}
                label="Единица измерения *"
                value={!!item.measurement_unit_type && item.measurement_unit_type.tag}
              />
            </div>

            <div className="Form__field">
              <Input type={'number'} reference={inputValue} label="Размер *"
                     value={!!item.value && !!item.measurement_unit_type ? (item.value / item.measurement_unit_type.ratio) : !!item.value && item.value}/>
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectComposition}
                options={!!object.compositions ? getSelectOptions(object.compositions) : getSelectOptions([])}
                label="На замену к"
                value={!!item.replacement && item.replacement.uuid}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.name} - {!!item.nomenclature && item.nomenclature.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalItemEdit;
