import React, {Fragment, useState} from "react";
import styleContent from "../../../../../../styles/modules/Block.module.scss";
import ModalItemEdit from "./ModalItemEdit";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";

function ItemsList(props) {
  const {specification, object, setIsUpdateList, isUpdateList} = props

  const [modalItemEdit, setModalItemEdit] = useState(null)

  function getAmountComposition(composition) {
    if (!!composition && !!composition.nomenclature && !!composition.nomenclature.price) {
      const amount = composition.value / (composition.nomenclature.value / composition.nomenclature.price)
      return (amount / 100).toFixed(3);
    }
    return 0;
  }

  return (
    <>
      {!!specification && !!specification.compositions && !!specification.compositions.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title}>Позиции</div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                {specification.compositions.map((composition, compositionIndex) => (
                  <Fragment key={"composition_" + composition.uuid}>
                    <tr
                      className="Table__tr Table__tr_hover"
                      // key={props.specification.uuid + ":" + composition.uuid}
                      onClick={!specification.code ? () => setModalItemEdit(composition) : () => null}
                      style={{
                        cursor: !specification.code ? "pointer" : "default",

                        borderStyle: "2px solid",
                        borderColor: "#bdbdbd"
                      }}
                    >
                      <td
                        className="Table__td"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className="Td__title Td__title_flex">
                          {compositionIndex + 1} #{" "}
                          {composition.nomenclature.name}
                          {composition.nomenclature
                            .specification_count > 0 && (
                            <div
                              className="Marker Marker_left Marker_inline Marker_small"
                            />
                          )}
                        </div>
                      </td>
                      <td
                        className="Table__td"
                        style={{
                          textAlign: "right",
                          fontSize: 20,
                          fontWeight: 700,
                          opacity: .33
                        }}
                      >
                        {(!!composition.quantity && getAmountComposition(composition)) || 0}
                        <span style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: 600,
                          marginLeft: 6
                        }}>
                          руб
                        </span>
                      </td>
                      <td
                        className="Table__td"
                        style={{
                          textAlign: "right",
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        {(!!composition.quantity && composition.quantity) || "-"}
                      </td>
                      <td
                        className="Table__td Table__td_all-border"
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        {/*{!!composition.measurement_type && composition.measurement_type.designation}*/}
                        {!!composition.measurement_unit_type ? composition.measurement_unit_type.designation :
                          (!!composition.measurement_type
                            && composition.measurement_type
                              .designation)}
                      </td>
                    </tr>

                    {!!composition.replacements && (
                      <tr
                        className={"Table__tr"}
                        style={{
                          borderStyle: "2px solid",
                          borderColor: "#dbdbdb",
                        }}
                      >
                        <td
                          className="Table__td"
                          colSpan={6}
                          style={{
                            // padding: "0 0 0 32px",
                            padding: 0
                          }}
                        >

                          <div style={{
                            display: "flex",
                            alignItems: "flex-start"
                          }}>

                            <Tooltip text={'На&nbsp;замену'}>
                              <div style={{
                                padding: 4,
                                transform: "rotate(45deg)"
                              }}>
                                <Icon name={"custom-swap-ver"} color={"#20202050"} size={32}/>
                              </div>
                            </Tooltip>

                            <table className="Table">
                              <thead>
                              <tr
                                className="Table__tr"
                                style={{
                                  backgroundColor: "#c5c5c5",
                                  borderStyle: "2px solid",
                                  borderColor: "#bdbdbd",
                                }}
                              >
                              </tr>
                              </thead>
                              <tbody>
                              {!!composition.replacements &&
                                composition.replacements.map((replacement, replacementIndex) => (
                                  <Fragment key={"replacement_" + replacement.uuid}>
                                    <tr
                                      className="Table__tr Table__tr_hover"
                                      // key={props.specification.uuid + ":" + replacement.uuid}
                                      onClick={!specification.code ? () => setModalItemEdit(replacement) : () => null}
                                      style={{
                                        cursor: !specification.code ? "pointer" : "default",

                                        borderStyle: "2px solid",
                                        borderColor: "#bdbdbd"
                                      }}
                                    >
                                      <td
                                        className="Table__td"
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <div className="Td__title Td__title_flex">
                                          {replacementIndex + 1} #{" "}
                                          {replacement.nomenclature.name}
                                          {replacement.nomenclature
                                            .specification_count > 0 && (
                                            <div
                                              className="Marker Marker_left Marker_inline Marker_small"
                                            />
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        className="Table__td"
                                        style={{
                                          textAlign: "right",
                                          fontSize: 20,
                                          fontWeight: 700,
                                          opacity: .33
                                        }}
                                      >
                                        {(!!replacement.quantity && getAmountComposition(replacement)) || 0}
                                        <span style={{
                                          textAlign: "center",
                                          fontSize: 14,
                                          fontWeight: 600,
                                          marginLeft: 6
                                        }}>
                          руб
                        </span>
                                      </td>
                                      <td
                                        className="Table__td"
                                        style={{
                                          textAlign: "right",
                                          fontSize: 20,
                                          fontWeight: 700,
                                        }}
                                      >
                                        {(!!replacement.quantity && replacement.quantity) || "-"}
                                      </td>
                                      <td
                                        className="Table__td Table__td_all-border"
                                        style={{
                                          textAlign: "center",
                                          fontSize: 14,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {/*{!!replacement.measurement_type && replacement.measurement_type.designation}*/}
                                        {!!replacement.measurement_unit_type ? replacement.measurement_unit_type.designation :
                                          (!!replacement.measurement_type
                                            && replacement.measurement_type
                                              .designation)}
                                      </td>
                                    </tr>
                                  </Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>

                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
                </tbody>
              </table>
            </div>
          </div>

          {!!modalItemEdit && (
            <ModalItemEdit
              object={specification}
              item={modalItemEdit}
              successfulRequest={() => setIsUpdateList(!isUpdateList)}
              handleClose={() => setModalItemEdit(null)}
            />
          )}
        </>
      )}
    </>
  );
}

export default ItemsList;
